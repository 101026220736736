<template>
  <div id="policy">
    <div>
        <h1 class="center">นโยบายความเป็นส่วนตัว</h1>
    </div>
    <div class="bg_msg">
        <div class="container"> 
        <h3 id="title">1. บทนำ</h3> 
        <p>
            บริษัท เอ็กซ์ซีรีน เทคโชไซอีตี้ จำกัด ได้กำหนดนโยบายการคุ้มครองข้อมูลส่วนบุคคล และให้ความสำคัญอย่างยิ่งกับ		
        ข้อมูลส่วนบุคคลของท่าน ในการเข้าถึงหรือการใช้บริการแอปพลิเคชั่น (Application) <b>"ดวงดีLive"</b>  หรือเว็บไซต์ 		
        (Website) <b>"www.Xserenedestiny.co.th"</b>  โดยการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่านอย่างเหมาะสม		
        ตรงตามความต้องการของท่าน และสอดคล้องถูกต้องตามกฏหมาย	
        </p>
        <br>

        <h3 id="title">2. บทนิยาม และคำจำกัดความ</h3> 	
        <p>
        <br>
           <b>"บริษัทฯ"</b>  หมายถึง <b>บริษัท เอ็กซ์ซีรีน เทคโชไซอีตี้ จำกัด</b>  รวมถึงบริษัทในเครือที่บริษัทฯเป็นหุ้นส่วน บริษัทย่อย ตัวแทน		
        พนักงานหรือผู้ที่ได้รับมอบอำนาจ		
        <br><br>       
        <b>"แอปพลิเคชั่น"</b>  หมายถึง แอปพลิเคชั่น (Application) ที่ใช้ชื่อ <b>"ดวงดี Live"</b>  บนระบบปฏิบัติการไอโอเอส iOS หรือระบบ		
        ปฏิบัติการแอนดรอยด์ Android		
        <br><br>
        <b>"เว็บไซต์"</b>  หมายถึง เว็บไซต์ (Website) ที่ใช้ชื่อ <b>www.Xserenedestiny.co.th</b> 	
        <br><br>  
        <b>"นโยบายฯ"</b>  หมายถึง นโยบายการคุ้มครองข้อมูลส่วนบุคคลของท่าน และหลักเกณฑ์ หรือวิธีการที่บริษัทฯ เก็บรวบรวม		
        ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน		
        <br><br>   
        <b>"กฏหมายคุ้มครองข้อมูลส่วนบุคคล"</b>  หมายถึง พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562		
        <br><br> 
        <b>"ประมวลผล"</b>  หมายถึง การเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่านเฉพาะเท่าที่จำเป็น และชอบด้วย		
        กฏหมายเท่านั้น ซึ่งการดำเนินการเก็บรวบรวม ใช้ และเปิดเผยจะใช้วิธีการทางระบบคอมพิวเตอร์หรือวิธีการอื่นตามที่		
        บริษัทฯ เห็นสมควร		
        <br><br> 
        <b>"ข้อมูลส่วนบุคคล"</b>  หมายถึง ข้อมูลเกี่ยวกับบุคคลที่ทำให้สามารถระบุตัวบุคคลได้		
        <br><br> 
        <b>"ข้อมูลส่วนบุคคลแฝง"</b>  หมายถึง ข้อมูลเกี่ยวกับบุคคลที่มีข้อมูลที่เกี่ยวข้องอื่นทำให้ไม่สามารถระบุตัวบุคคลได้ ข้อมูล		
        ที่เกี่ยวข้องจะจัดเก็บแยกออกจากกัน เพื่อให้ไม่สามารถระบุตัวบุคคลได้	
        </p>     
        <br>     
        
        
        <h3 id="title">3. วัตถุประสงค์ของการจัดทำนโยบายคุ้มครองข้อมูลส่วนบุคคล</h3> 
        <p>
            บริษัทฯ ได้กำหนดนโยบายฯ เพื่อให้ผู้ใช้บริการหรือลูกค้าของบริษัทฯ รับทราบนโยบายที่เกี่ยวกับข้อมูลส่วนบุคคลของ		
        ท่าน การดูแล รักษาความปลอดภัยของข้อมูลของท่าน และสิทธิของท่านตามกฏหมายคุ้มครองข้อมูลส่วนบุคคลอย่าง		
        เหมาะสม		
        <br> <br>   
        ข้อมูลส่วนบุคคลของท่าน เพื่อประมวลผลตามวัตถุประสงค์ที่ไม่จำกัดวัตถุประสงค์ มีดังนี้		
        <br>   
        1) เพื่อสมัครลงทะเบียนใช้บริการ และลงชื่อเข้าใช้บัญชี		
        <br>    
        2) เพื่อตรวจสอบการสมัครลงทะเบียนใช้บริการ และยืนยันตัวตน		
        <br>      
        3) เพื่อติดต่อ สื่อสาร โฆษณา หรือนำเสนอผลิตภัณฑ์หรือบริการที่เกี่ยวข้องระหว่างบริษัทฯ และผู้ใช้บริการ		
        <br>     
        4) เพื่อรายงานหรือเสนอแนะ ชี้แจงปัญหา ข้อเรียกร้อง ข้อสงสัยที่เกี่ยวข้องกับการใช้บริการ หรือความผิดปกติของ		
        การใช้บริการ		
        <br>     
        5) เพื่อนำส่งข้อมูลข่าวสาร หรือโปรโมชั่นจากบริษัทฯ		
        <br>  
        6) เพื่อนำส่งหนังสือรับรองการหักภาษี ณ ที่จ่าย หรือเอกสารที่เกี่ยวข้องกับการชำระเงินหรือการใช้บริการอื่น		
        <br>     
        7) เพื่อสำรวจ วิเคราะห์ วัดผล และปรับปรุงการให้บริการ		
        <br> 
        8) เพื่อป้องกันอาชญากรรมทางคอมพิวเตอร์ การฉ้อโกง หรือการทุจริตในการใช้บริการบนแอปพลิเคชั่น หรือเว็บไซต์		
        <br>  
        9) เพื่อปฏิบัติตามกฏหมายคุ้มครองข้อมูลส่วนบุคคล หรือคำสั่งทางกฏหมาย		
        <br>      
        10) เพื่อปฏิบัติตามกฏหมายอื่นที่เกี่ยวข้อง เช่น พระราชบัญญัติว่าด้วยการกระทำผิดเกี่ยวกับคอมพิวเตอร์ พระราช		
        บัญญัติว่าด้วยธุรกรรมอิเล็กทรอนิกส์ พระราชบัญญัติคุ้มครองผู้บริโภค		
        <br> <br>     
        การประมวลผลข้อมูลส่วนบุคคลบางกรณีต้องขอความยินยอมจากท่าน เพื่อประมวลผลตามวัตถุประสงค์ที่ไม่จำกัด		
        วัตถุประสงค์ มีดังนี้		
        <br>    
        1) ข้อมูลส่วนบุคคลที่อ่อนไหว 		
        <br>    
        2) การเปิดเผยข้อมูลส่วนบุคคลของท่านให้กับพันธมิตรทางธุรกิจของบริษัทฯ หรือบุคคลภายนอก		
        <br>     
        3) กรณีท่านเป็นผู้เยาว์ คนเสมือนไร้ความสามารถ คนไร้ความสามารถ ต้องได้รับความยินยอมจากบิดามารดา ผู้ปกครอง		
        ผู้อนุบาล หรือผู้พิทักษ์ (แล้วแต่กรณี) เว้นแต่กฏหมายคุ้มครองข้อมูลส่วนบุคคคลกำหนดให้สามารถทำได้ โดยไม่ต้อง		
        ได้รับความยินยอม		
        </p>		              
        <br>
             
        
        <h3 id="title">4. ข้อมูลส่วนบุคคลที่บริษัทฯ เก็บรวบรวม ใช้ และเปิดเผย</h3>  	
        <p>
        <br>
         บริษัทฯ เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน ดังนี้		
        <br>
        1) ข้อมูลบ่งชี้ตัวตนของผู้ให้บริการ ได้แก่ ชื่อและนามสกุล เพศ วันเดือนปีเกิด รูปถ่าย หมายเลขบัตรประจำตัวประชาชน		
        สำเนาบัตรประจำตัวประชาชน 		
        <br>   
        2) ข้อมูลทางการเงินของผู้ให้บริการ ได้แก่ ข้อมูลบัญชีธนาคาร สำเนาสมุดบัญชีธนาคาร		
        <br>     
        3) ข้อมูลที่ใช้ในการติดต่อของผู้ใช้บริการ ได้แก่ หมายเลขโทรศัพท์ ที่อยู่อีเมล์ ที่อยู่		
        <br>      
        4) ข้อมูลการเชื่อมต่อของผู้ใช้บริการ ได้แก่ ชื่อผู้ใช้บริการ ประวัติการชำระเงิน ประวัติการใช้บริการ		
        <br>     
        5) ข้อมูลบันทึกการติดตามตรวจสอบกิจกรรมของผู้ใช้บริการ ได้แก่ Cookie Pixel 		
        <br>      
        6) ข้อมูลสื่อออนไลน์ ได้แก่ บัญชีสื่อออนไลน์ ข้อมูลโปรไฟล์ของท่าน		
        <br>      
        7) ข้อมูลการตลาด ได้แก่ การให้คะแนนและรีวิวความพอใจ	 
        </p>	
        <br>

        <h3 id="title">5. ช่องทางที่บริษัทฯได้รับข้อมูลส่วนบุคคล</h3> 		
        <p>
        <br>
            บริษัทฯ ได้รับข้อมูลส่วนบุคคลจากท่านจากช่องทางการสื่อสารระหว่างท่านกับบริษัทฯ หรือช่องทางอื่น ดังนี้		
        <br>  
        1) เมื่อท่านได้สมัครลงทะเบียนใช้บริการ และยืนยันตัวตนกับบริษัทฯ		
        <br>    
        2) เมื่อท่านได้ใช้บริการผ่านแอปพลิเคชั่น หรือเว็บไซต์ 		
        <br>   
        3) เมื่อท่านได้ติดต่อกับบริษัทฯ ทางอีเมล์ สื่อออนไลน์ โทรศัพท์		
        <br>    
        4) เมื่อท่านได้ทำแบบสำรวจ หรือการส่งเสริมทางการตลาดของบริษัทฯ		
        <br>    
        5) เมื่อท่านได้ชำระเงินกับบริษัทฯ		
        <br>     
        6) ข้อมูลจากฐานข้อมูลเปิดของหน่วยงานราชการ		
        </p>  
        <br>


        <h3 id="title">6. การเปิดเผยข้อมูลส่วนบุคคลของท่าน</h3> 		
        <p>
            บริษัทฯ จะไม่เปิดเผยข้อมูลส่วนบุคคลของท่านให้กับบุคคลภายนอก หรือองค์กรภายนอก หากไม่ได้รับความยินยอม		
        จากท่าน เว้นแต่บริษัทฯ จำเป็นต้องปฏิบัติตามกฏหมาย และบริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคลของท่านให้กับ		
        บุคคลภายนอก หรือองค์กรภายนอก ตามวัตถุประสงค์ที่ได้ระบุไว้ในนโยบายความเป็นส่วนตัวนี้แล้ว		
        </p>
        <br>


        <h3 id="title">7. การส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศ</h3> 	
        <p>
            บริษัทฯ อาจมีความจำเป็นในการส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังผู้รับข้อมูลปลายทางในต่างประเทศ ซึ่งเป็น		
        การดำเนินการทางธุรกิจตามปกติ โดยการส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปจัดเก็บไว้ใน Server หรือ Cloud ใน		
        ต่างประเทศ ซึ่งข้อมูลส่วนบุคคลของท่านที่ถูกส่งหรือโอนไปยังผู้รับข้อมูลปลายทางในต่างประเทศ บริษัทฯ จะดำเนิน		
        การให้มีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอ และจัดเก็บรวบรวมข้อมูลส่วนบุคคลของท่านอย่างปลอดภัย	
        </p>  
        <br>
                

        <h3 id="title">8. ระยะเวลาการจัดเก็บข้อมูลส่วนบุคคลของท่าน</h3>		
        <p>
            บริษัทฯ จะจัดเก็บข้อมูลส่วนบุคคลของท่านไว้ในระยะเวลาที่แตกต่างไม่เท่ากัน การจัดเก็บข้อมูลส่วนบุคคลของท่านจะ		
        จัดเก็บตราบเท่าที่ท่านเป็นผู้ใช้บริการหรือลูกค้าของบริษัทฯ เมื่อบริษัทฯ ไม่มีความจำเป็นที่ต้องจัดเก็บข้อมุลส่วนบุคคล		
        ของท่าน บริษัทฯ จะลบข้อมูลส่วนบุคคลของท่าน หรืออาจจะจัดเก็บข้อมูลในรูปแบบข้อมูลส่วนบุคคลแฝงที่ไม่สามารถ		
        ระบุตัวตนของท่านได้		
        </p>
        <br>
                

        <h3 id="title">9. มาตรฐานการคุ้มครองข้อมูลส่วนบุคคลของท่าน</h3>		
        <p>
            บริษัทฯ จะรักษามาตรฐานการจัดเก็บรักษาความปลอดภัยกับข้อมูลส่วนบุคคลของท่านอย่างเคร่งครัด หากข้อมูลส่วน		
        บุคคลของท่านเกิดสูญหาย การจารกรรมข้อมูล การจู่โจม ไวรัสคอมพิวเตอร์ หรือการบุกรุกระบบรักษาความปลอดภัย		
        จากบุคคลภายนอก หรือองค์กรภายนอก บริษัทฯ ของสงวนสิทธิในการรับผิดชอบที่เกี่ยวข้องนั้น	
        </p>
        <br>
            
        
        <h3 id="title">10. สิทธิของท่านในการควบคุมข้อมูลส่วนบุคคล</h3>		
        <p>
        <br>
        ท่านสามารถใช้สิทธิต่างๆ เกี่ยวกับข้อมูลส่วนบุคคลของท่านได้ตามกฏหมายคุ้มครองข้อมูลส่วนบุคคล ดังนี้		
        <br>
        <br>
        1) การเพิกถอนความยินยอม ท่านมีสิทธิเพิกถอนความยินยอมได้ตลอดเวลา ท่านสามารถยื่นคำขอใช้สิทธิเพิกถอน		
        ความยินยอมมายังบริษัทฯ ทางอีเมล์ <b>admin_DuangD@gmail.com</b>  บริษัทฯ จะดำเนินการตามคำขอใช้สิทธิเพิกถอน		
        ความยินยอมภายใน 30 วัน บริษัทฯ ขอสงวนสิทธิปฏิเสธคำขอที่ไม่สมเหตุสมผล ไม่สอดคล้องตามกฏหมาย หรือไม่		
        สามารถปฏิบัติได้จริง		
        <br> 
        2) ท่านมีสิทธิเข้าไปแก้ไข เปลี่ยนแปลงข้อมูลส่วนบุคคลของท่านให้ถูกต้อง สมบูรณ์ และครบถ้วนตรงความเป็นจริงใน		
        ปัจจุบันอยู่เสมอ		
        <br> 
        3) ท่านมีสิทธิคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่าน ระงับการใช้ข้อมูลส่วนบุคคลของท่านชั่วคราว หรือขอ		
        ให้ลบข้อมูลตามขอบเขตของกฏหมายคุ้มครองข้อมูลส่วนบุคคล ท่านสามารถยื่นคำขอมายังบริษัทฯ ทางอีเมล์ <b>admin_DuangD@gmail.com</b>		
         บริษัทฯ จะดำเนินการตามคำขอภายใน 30 วัน บริษัทฯ ขอสงวนสิทธิคำขอที่ไม่สมเหตุ		
        สมผล ไม่สอดคล้องตามกฏหมาย หรือไม่สามารถปฏิบัติได้จริง		
        <br> 
        4) ท่านมีสิทธิของการเข้าถึงและขอสำเนาข้อมูลส่วนบุคคลที่อยู่ในความรับผิดชอบของบริษัทฯ 
        </p> 
        <br>
        		
                
        <h3 id="title">11. คุกกี้ และการใช้งานคุกกี้ (Cookie)</h3> 		
        <p>
            บริษัทฯ อาจเก็บรวบรวม ใช้ และเปิดเผยคุกกี้ หรือเทคโนโลยีในลักษณะเดียวกัน เมื่อท่านเข้าถึงหรือการใช้บริการ		
        แอปพลิเคชั่นหรือเว็บไซต์ของบริษัทฯ ซึ่งมีวัตถุประสงค์ ดังนี้		
        <br><br>   
        1) เพื่อป้องกันการฉ้อโกง และปรับปรุงระบบความปลอดภัย		
        <br>     
        2) เพื่อศึกษาการเข้าถึงและการใช้แอปพลิเคชั่นหรือเว็บไซต์แล้วปรับปรุงแอปพลิเคชั่นหรือเว็บไซต์ให้เหมาะสมและดีขึ้น		
        <br>      
        3) เพื่อนำเสนอผลิตภัณฑ์ หรือบริการ และการทำการตลาดอย่างเหมาะสม		
        <br>     
        ท่านสามารถปฏิเสธการรวบรวมข้อมูลคุกกี้ได้ผ่านการตั้งค่าบราวน์เซอร์ของท่าน
        </p>     
        <br>	
                
        <h3 id="title">12. การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</h3>		
        <p>
            บริษัทฯ จะแก้ไข เปลี่ยนแปลงนโยบายความเป็นส่วนตัวเพื่อให้สอดคล้องตามกฏหมาย โดยไม่ต้องแจ้งล่วงหน้า และ		
        ถือว่ามีผลบังคับทันที ดังนั้นขอให้ตรวจสอบนโยบายความเป็นส่วนตัว กรณีท่านไม่ยอมรับการแก้ไขหรือแปลี่ยนแปลง		
        ท่านจะไม่สามารถเข้าสู่ระบบการใช้บริการได้	
        </p>
        <br>	
                

        <h3 id="title">13. การติดต่อบริษัทฯ</h3>	
        <p>
            หากท่านมีข้อเสนอแนะ หรือต้องการสอบถามข้อมูล ท่านสามารถติดต่อบริษัทฯ ทางอีเมล์ <b>admin_DuangD@gmail.com</b> 		
        หรือ<b> บริษัท เอ็กซ์ซีรีน เทคโชไซอีตี้ จำกัด</b> เลขที่ 68 หมู่ที่ 7 ตำบลเสม็ด อำเภอเมืองชลบุรี จังหวัดชลบุรี 20130	
        </p> 
        <br>
        </div>
    </div>
    <div class="center">
        <div>
            <h3>COPYRIGHT ©  บริษัท เอ็กซ์ซีรีน เทคโชไซอีตี้ จำกัด</h3>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name : "PrivacyPolicy"
}
</script>

<style>
#title{
    text-align: start;
}
.container{
    padding: 50px;
}
p{
  font-size : 16px;
  line-height : 24px;
  text-align: justify;
  text-indent: 10%;
}
.center{
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  background-color: #FFC000;
}
.bg_msg{
  background-color: white;
  width: 1200px;
  max-width: 1200px;
  height: 3650px;
  border-radius: 30px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
}
@media screen and (max-width: 767px){
    .bg_msg{
    background-color: white;
    width: 600px;
    max-width: 600px;
    height: 5000px;
    border-radius: 30px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    }
}
@media (min-width: 767px) and (max-width: 1023px) {
    .bg_msg{
    background-color: white;
    width: 800px;
    max-width: 800px;
    height: 4200px;
    border-radius: 30px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    }
}
@media screen and (min-width: 1024px){
    .bg_msg{
    background-color: white;
    width: 1200px;
    max-width: 1200px;
    height: 3650px;
    border-radius: 30px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    }
}


</style>