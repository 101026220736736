<template>
    <router-view/>
</template>

<script>
export default{};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html,
body {
  margin: 0px;
  padding: 0px;
  background: linear-gradient(
    0deg,
    rgba(0, 30, 47, 1) 0%,
    rgba(0, 74, 97, 1) 23.14%,
    rgba(49, 145, 168, 1) 61.97%,
    rgba(124, 192, 215, 1) 94.68%
  );
}

@media screen and (max-width: 767px) {
  html,
  body {
    margin: 0px;
    padding: 0px;
    background: linear-gradient(
      0deg,
      rgba(0, 30, 47, 1) 0%,
      rgba(0, 74, 97, 1) 23.14%,
      rgba(49, 145, 168, 1) 61.97%,
      rgba(124, 192, 215, 1) 94.68%
    );
  }
 
}

@media (min-width: 767px) and (max-width: 1023px) {
  html,
  body {
    margin: 0px;
    padding: 0px;
    background: linear-gradient(
      0deg,
      rgba(0, 30, 47, 1) 0%,
      rgba(0, 74, 97, 1) 23.14%,
      rgba(49, 145, 168, 1) 61.97%,
      rgba(124, 192, 215, 1) 94.68%
    );
  }
}
@media screen and (min-width: 1024px) {
  html,
  body {
    margin: 0px;
    padding: 0px;
    background: linear-gradient(
      0deg,
      rgba(0, 30, 47, 1) 0%,
      rgba(0, 74, 97, 1) 23.14%,
      rgba(49, 145, 168, 1) 61.97%,
      rgba(124, 192, 215, 1) 94.68%
    );
  }
}
</style>
