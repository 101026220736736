<template>
  <div>
    <img src="../assets/Logo.png" alt="Logo" class="default-img logo" />
  </div>

  <div >
    <h1 class="center">ABOUT  US</h1>
  </div>

  <div>
    <div class="bg_msg">
      
      <br><br>
      <p> 
          ดวงดี Live (หรือดวงดี ไลฟ์) เป็นแอปพลิเคชั่นการดูดวงอีกรูปแบบหนึ่งที่ไม่เหมือนใคร เป็นแพลตฟอร์ม
      ที่รวบรวมหมอดูในทุกศาสตร์การดูดวง ซึ่งเป็นผู้ที่มากความสามารถ และเต็มไปด้วยทักษะการทำนายหรือพยากรณ์
      ดวงชะตา โดยจะทำนายหรือพยากรณ์ดวงชะตาผ่านแอปพลิเคชั่น หรือวีดิโอคอลโดยตรงกับท่านเปรียบเสมือนได้นั่ง
      ต่อหน้า และได้ใกล้ชิดกัน จนทำให้การดูดวงเป็นเรื่องที่ง่ายขึ้น ไม่ยุ่งยากอีกต่อไป  และยังสามารถดูได้ทุกที่  ทุกเวลา 
      เพียงแค่เชื่อมต่อกับระบบอินเตอร์เน็ตเท่านั้น เพราะการดูดวงการทำนาย หรือการพยากรณ์ดวงชะตานั้น   เป็นสิ่งที่
      สามารถเข้าถึงได้ทุกเพศ ทุกวัย แอปพลิเคชั่น ดวงดี Live จึงตอบโจทย์สำหรับท่านที่ไม่มีเวลา ไม่สะดวกในเดินทาง 
      หรือไม่รู้จะดูดวงกับหมอดูท่านไหน    รวมทั้งช่องทางการดูดวงที่อาจจะเข้าถึงได้ลำบาก    แอปพลิเคชั่น ดวงดี Live 
      สามารถเลือกหมอดูได้อย่างอิสระตามความพึงพอใจของท่านเอง 
      </p>
      <p>
        <br>การใช้งานแอปพลิเคชั่น ดวงดี Live ที่แสนง่าย และไม่ยุ่งยาก
        <br>- เลือกศาสตร์การดูดวงที่ท่านสนใจ เพื่อจะได้ดูดวง ทำนาย หรือพยากรณ์ดวงชะตา ที่ตรงตามความต้องการ
        <br>- เลือกหมอดูที่ถูกใจ เพื่อจะได้ดูดวง ทำนาย หรือพยากรณ์ดวงชะตาให้แก่ท่าน
        <br>- นัดหมายวันเวลาที่จะดูดวง ทำนาย หรือพยากรณ์ดวงชะตาตามตารางของหมอดูที่ได้กำหนดไว้
        <br>- เพลิดเพลินกับการดูดวง ทำนาย หรือพยากรณ์ดวงชะตาที่สามารถเปิดกล้องพูดคุย สอบถาม ทำนายหรือ
          พยากรณ์ดวงชะตาด้วยระบบภาพที่คมชัด
      </p>
      
      <p>
        เชิญท่านใช้บริการแอปพลิเคชั่น ดวงดี Live ได้ทันทีเพื่อดูดวง ทำนาย หรือพยากรณ์ดวงชะตา และ”ดวงดี”
      ไปด้วยกัน
      </p> 
      <br><br>
    </div>
  </div>

  <div >
    <h1 class="center">FAQS</h1>
  </div>

  <div class="bg_faqs">

    <div class="bg_faqs1">
      <p> 
        <br>1.ผู้ใช้บริการสามารถเข้าสู่ระบบด้วยวิธีใดได้บ้างผู้ใช้บริการสามารถเข้าสู่ระบบโดยใช้บัญชีด้านล่างนี้ได้ทันที
        <br>1.1 Facebook
        <br>1.2 Google
        <br>1.3 Line

        <br>
        
        <br> 2. การชำระเงินในแอปพลิเคชัน สามารถชำระด้วยวิธีใดได้บ้าง
        <br>2.1) ชำระโดยแสกน QR Code
        <br>2.2) อินเทอร์เน็ตแบงค์กิ้ง
        <br>2.3) บัตรเครดิตหรือเดบิต*
        <br>2.4) โมบายแบงค์กิ้ง
        <br>*ดวงดีไลฟ์   ไม่มีนโยบายที่จัดเก็บข้อมูลบัตรเครดิตหรือเดบิต ของลูกค้าไว้ในทุกกรณี

        
        <br><br>3.การเช็คประวัติการดูดวง คลิกเลือกที่สัญลักษณ์เมนู (มุมบนซ้ายมือ) แล้วคลิกเลือกที่ประวัติการดูดวง

        <br><br>4.การเข้ามาใช้บริการเพื่อให้บริการหรือรับบริการผู้ให้บริการ หรือหมอดูสามารถเข้ามารอให้บริการล่วงหน้าได้ 10 นาทีก่อน เวลานัดหมาย โดยคลิกที่หน้านัดหมาย และเลือกการนัดหมาย แล้วคลิกที่สัญลักษณ์กล้อง  หากพบว่าหมอดูเข้ามาช้าเกิน 10 นาที การให้บริการถือเป็นโมฆะ โดยหมอดูจะไม่ได้เงินค่าบริการ และนำส่งเงินคืนแก่ผู้รับบริการต่อไป       หากผู้รับบริการหรือ ลูกค้าไม่เข้ามารับบริการ หมอดูจะต้องรอให้บริการจนหมด
        เวลานัดหมายที่ทางลูกค้าจองไว้ จึงจะได้รับเงินค่าบริการ

        <br><br>5.การเลือกศาสตร์ที่ต้องการดูดวงสามารถเลือกศาสตร์ที่ ต้องการดูดวงได้มากสุด 3 ตัวเลือกเพื่อทำการคัดกรองหมอดู ตามที่ลูกค้าต้องการ

        <br><br>6.หมอดูคู่แมท คืออะไรหมอดูคู่แมท คือ หมอดูที่ทางดวงดี ไลฟ์ทำการคัดกรองให้ตรงกับที่ลูกค้าต้องการ

      </p>
    </div>

    <div class="bg_faqs2">
      <p>
      <br>
      7.หมอฮอต...ยอดฮิตคืออะไรหมอฮอต...ยอดฮิตคือหมอดูที่ได้ รับความนิยมจากการประเมินคะแนนความพึงพอใจท่านสามารถ เลือกดูตามศาสตร์พยากรณ์จากหมอดูฮอตๆได้เลย

      <br><br>8.การจองนัดหมายหมอดูต้องทำอย่างไรท่านสามารถคลิกเข้าไปที่ชื่อหมอดูที่ต้องการแล้วเลือกวันที่เวลาและศาสตร์การดูดวงที่ต้องการ หลังจากนั้นให้ตรวจสอบข้อมูล และชำระเงิน

      <br><br>9.หากเลือกการจองเสร็จแล้วไม่สามารถดำเนินการชำระเงินต่อได้ ทำอย่างไร ปัญหานี้อาจจะเกิดจากการจองเวลานัดหมายเดียวกัน โดยลูกค้าอีกท่านชำระเงินเสร็จก่อน ทำให้ไม่สามารถชำระเงินได้ แนะนำให้เลือกจองเวลานัดหมายใหม่อีกครั้ง

      <br><br>10.การยกเลิกการนัดหมายกับหมอดูต้องทำอย่างไรในกรณีที่ลูกค้านัดหมายกับหมอดูเรียบร้อยแล้ว จะไม่สามารถยกเลิกการนัด
      หมายนั้นได้

      <br><br>11.หากการบริการมีการเชื่อมต่อที่ไม่เสถียรต้องทำอย่างไรแนะนำให้ลูกค้าตรวจสอบระบบอินเทอร์เน็ตของท่านก่อนเข้ามารับบริการเนื่องจากจะไม่สามารถรีเซ็ตอินเทอร์เน็ตระหว่างการใช้บริการได้

      <br><br>12.หากมีการโทรเข้ามาระหว่างการบริการต้องทำอย่างไรท่านสามารถกดรับสายได้โดยการบริการจะพักสายไว้ก่อนแล้วท่านสามารถกลับเข้ามาใช้บริการต่อได้แต่เวลาให้บริการจะดำเนินต่อไม่ได้ หยุดเวลาให้

      <br><br>13.หากหมดเวลาแล้วยังต้องการสอบถามหมอดูต่อต้องทำอย่าง ไรเมื่อหมดเวลาระบบจะตัดอัตโนมัติแนะนำให้ท่านทำการจองหมอดูและชำระเงินอีกครั้ง

      <br><br>14.หากมีปัญหาด้านการบริการอื่นๆติดต่อได้ที่อีเมลล์ Admin_duangD@gmail.comโดยทางดวงดีไลฟ์จะติด ต่อกลับเร็วที่สุด
      </p>
    </div>

  </div>


  <div>
    <h2>ดาว์นโหลดแอพพลิเคชั่น</h2>
    <div class="download_app_container">

        <div class="app_store_container">
          <div id="acessory1">
            <img
              src="../assets/accessory2.png"
            />
          </div>

          <div class="qr_app_store_container">
            <div class="qr_app_store">
               <img src="../assets/qrcode_ios.png" class="qrcode_image">
            </div>
            <img src="../assets/btn_app_store.png" class="btn_app_store" @click="openUrl('https://apps.apple.com/th/app/%E0%B8%94%E0%B8%A7%E0%B8%87%E0%B8%94-live/id1658359250?l=th')"/>
          </div>
        </div>


        <div class="play_store_container">
              <div class="qr_play_store_container">
                <div class="qr_play_store">
                  <img src="../assets/qrcode_android.png" class="qrcode_image">
                </div>
                <img src="../assets/btn_play_store.png" class="btn_play_store" @click="openUrl('https://play.google.com/store/apps/details?id=com.xserene.duangdeelive')"/>
              </div>

              <div id="acessory2">
                <img
                  src="../assets/accessory1.png"
                />
            </div>
        </div>
    </div>
  </div >
  
  <div class="bg_footer">
    <div>
        <h3>ติดตามเราได้ที่</h3>
        <a href="https://web.facebook.com/DuangDlive"><img class="icon" src="../assets/square-facebook.svg" alt=""></a>
        <a href="https://lin.ee/jveBaF2"><img class="icon" src="../assets/line.svg" alt=""></a>
        <a href="https://twitter.com/DuangDlive"><img class="icon" src="../assets/square-twitter.svg" alt=""></a>
    </div>

    <div>

        <h4><router-link to="/PrivacyPolicy">นโยบายความเป็นส่วนตัว</router-link></h4>
        <h3>COPYRIGHT ©  บริษัท เอ็กซ์ซีรีน เทคโชไซอีตี้ จำกัด</h3>
    </div>
    

  </div>
 
</template>

<script>
export default {
  methods: {
    openUrl(url) {
      console.log('test');
      window.open(url, '_blank', 'noreferrer');
    }
  }
};
</script>

<style scoped>
.default-img {
  pointer-events: none;
}
.logo {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: #FFC000;
}
h1{
  font-size : 40px;
}
h2{
  font-size : 36px;
  color: white;
  padding: 50px;
}
h3{
  font: 24px;
  padding-top: 20px;
  color: #003454;
}
h4{
  font-size: 16px;
  text-decoration: underline;
  color: #003454;
}
p{
  padding: 0 50px 0 50px;
  font-weight : bold;
  font-size : 20px;
  line-height : 36px;
  text-align: justify;
  text-indent: 10%;
}
.bg_msg{
  background-color: white;
  width: 1200px;
  max-width: 1200px;
  height: 730px;
  border-radius: 30px;
  margin-top: 83px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 135px;
}
.bg_faqs{
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 15px;
  padding-right: 15px;
  gap: 10px;
}
.bg_faqs1{
    background: white;
    margin-left: auto;
    margin-right: 50px;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 550px;
    max-width: 550px;
    height: 1420px;
    border-radius: 20px;
}
.bg_faqs2{
    background: white;
    margin-left: 50px;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 550px;
    max-width: 550px;
    height: 1420px;
    border-radius: 20px;
}

.download_app_container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.app_store_container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.play_store_container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}
.qr_app_store_container {
  display: grid;
  grid-template-rows: 1fr 100px;
  justify-content: center;
  align-items: center;
}

.qr_play_store_container{
  display: grid;
  grid-template-rows: 1fr 100px;
  justify-content: center;
  align-items: center;
 } 
 .qr_app_store{
  background: white;
  width: 300px;
  height: 300px;
  border-radius: 20px;
  margin-top: 50px;
}
.qrcode_image {
  padding: 25px;
  width: 250px;
  height: 250px;
}
 .qr_play_store{
  background: white;
  width: 300px;
  height: 300px;
  border-radius: 20px;
  margin-top: 50px;
}
.btn_app_store {
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.btn_play_store {
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
} 
.bg_footer{
  background: #FFC000;
  width: auto;
  height: 250px;
}
.icon{
  width: 50px;
  height: 50px;
  padding: 0px 10px 0px 10px;
}

@media screen and (max-width: 767px){
  p{
    padding: 0 50px 0 50px;
    font-weight : bold;
    font-size : 12px;
    line-height : 20px;
    text-align: justify;
    text-indent: 10%;
  }
  .bg_msg{
    background-color: white;
    width: 400px;
    height: 700px;
    border-radius: 30px;
    margin-top: 83px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 135px;
  }
  .bg_faqs{
    grid-template-columns: 1fr;
  }
  .bg_faqs1{
    background: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 400px;
    height: 700px;
    border-radius: 20px;
}
  .bg_faqs2{
      background: white;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      margin-bottom: 20px;
      width: 400px;
      height: 740px;
      border-radius: 20px;
  }
  .download_app_container {
    grid-template-columns: 1fr;
  }
  .app_store_container{
    grid-template-columns: 1fr;
  }

  .play_store_container{
    grid-template-columns: 1fr;
  }
  #acessory1{
    display: none;
  }
  #acessory2{
    display: none;
  }
}
@media (min-width: 767px) and (max-width: 1023px) {
  p{
    padding: 0 50px 0 50px;
    font-weight : bold;
    font-size : 20px;
    line-height : 36px;
    text-align: justify;
    text-indent: 10%; 
  }
  .bg_msg{
    background-color: white;
    width: 600px;
    max-width: 600px;
    height: 1200px;
    border-radius: 30px;
    margin-top: 83px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 135px;
  }
  .bg_faqs{
    grid-template-columns: 1fr ;
  }
  .bg_faqs1{
    background: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 550px;
    max-width: 550px;
    height: 1420px;
    border-radius: 20px;
}
  .bg_faqs2{
      background: white;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      margin-bottom: 20px;
      width: 550px;
      max-width: 550px;
      height: 1420px;
      border-radius: 20px;
  }
  .download_app_container {
    grid-template-columns: 1fr;
  }
  .app_store_container{
    grid-template-columns: 1fr;
  }
  .play_store_container{
    grid-template-columns: 1fr;
  }
  #acessory1{
    display: none;
  }
  #acessory2{
    display: none;
  }
}

@media screen and (min-width: 1024px){
  
  .bg_msg{
    background-color: white;
    width: 1200px;
    max-width: 1200px;
    height: 730px;
    border-radius: 30px;
    margin-top: 83px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 135px;
  }
  .bg_faqs{
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    display: grid;
    padding-left: 15px;
    padding-right: 15px;
    gap: 10px;
  }
  .bg_faqs1{
    background: white;
    margin-left: auto;
    margin-right: 50px;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 550px;
    max-width: 550px;
    height: 1420px;
    border-radius: 20px;
  } 
  .bg_faqs2{
      background: white;
      margin-left: 50px;
      margin-right: auto;
      margin-top: 30px;
      margin-bottom: 20px;
      width: 550px;
      max-width: 550px;
      height: 1420px;
      border-radius: 20px;
  }
} 

</style>
